@font-face {
  font-family: MainBasketRun;
  src: url("LuckiestGuy-Regular.46f2bef8.ttf");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: FagoCol;
  src: url("FagoCoLf-Bold.488982d3.ttf");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: FagoCoLfBlack;
  src: url("FagoCoLf-Black.3c1b1f7c.ttf");
  font-style: normal;
  font-display: swap;
}

body {
  background: linear-gradient(#dc6c25, #ff902a);
  margin: 0;
  padding: 0;
}

canvas {
  background-color: #df7734;
  width: 100%;
  height: 100%;
  position: absolute;
}

#fontsCont {
  font-family: MainBasketRun;
  font-size: 2pt;
  font-weight: bold;
  position: absolute;
}

#reportMessages {
  color: #fff;
  z-index: 10;
  opacity: .63;
  width: 120px;
  padding: 5px;
  font-size: 12pt;
  display: none;
  position: absolute;
  top: 100px;
  left: 10px;
}

#preloader {
  z-index: 10;
  background: url("preloader-bg.04d19298.jpeg") center / cover no-repeat;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.preloader-container {
  text-align: center;
}

#preloader img.logo {
  min-width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.image-text-container {
  display: inline-block;
  position: relative;
}

.image-text-container img {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
}

.image-text-container p {
  color: #fff;
  text-align: left;
  width: 50px;
  margin: 0 0 0 -3px;
  font-size: 24px;
  font-weight: 900;
  display: inline-block;
}

#tutorial {
  z-index: 2;
  color: #fff;
  background-color: #cf3a2b;
  border: 4px solid #cf3a2b;
  border-radius: 32px;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  max-width: 800px;
  height: 80vh;
  margin: auto;
  padding: 0;
  font-family: FagoCol;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#tutorial-header {
  text-align: center;
  z-index: 3;
  background: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

#tutorial-header h2 {
  text-align: center;
  flex-grow: 1;
  width: calc(100% - 20px);
  margin: 0 0 0 50px;
  font-family: FagoCoLfBlack;
  font-size: 36px;
  font-weight: 900;
  line-height: 60px;
}

#tutorial-header .close-btn {
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  height: 32px;
  margin: 0;
  padding: 0;
  display: flex;
}

#tutorial-header .close-btn img {
  width: 32px;
  height: 32px;
  margin: 10px;
}

#tutorial-content {
  background: #721d18;
  border-radius: 0 0 32px 32px;
  flex-grow: 1;
  margin-top: 60px;
  padding: 10px;
  overflow-y: auto;
}

#tutorial-content .inner-content {
  margin: 12px;
}

#tutorial-content .block {
  margin-bottom: 32px;
}

#tutorial-content h4 {
  margin: 16px 0 20px;
  padding-top: 10px;
  font-family: FagoCoLfBlack;
  font-size: 24px;
}

#tutorial-content h6 {
  font-family: FagoCoLfBlack;
  font-size: 16px;
  font-weight: 900;
}

#tutorial-content p {
  font-size: 16px;
  font-weight: 900;
  line-height: 18px;
}

#tutorial-content .product-line {
  padding-top: 10px;
  padding-bottom: 10px;
}

.product-line {
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
}

.cart-control {
  background: url("cart.63f8ab5c.png") center no-repeat;
  height: 200px;
  margin-bottom: 20px;
  display: flex;
}

.cart-control img {
  text-align: center;
  margin: auto;
  padding-top: 90px;
  display: inline-block;
}

.cart-control img:nth-of-type(2) {
  padding-top: 110px;
}

.item-content {
  flex-grow: 1;
}

.items-list .item {
  align-items: flex-start;
  margin-top: 8px;
  padding: 0;
  display: flex;
}

.items-list .item img {
  padding: 10px 16px 10px 10px;
}

h6 {
  margin: 0 0 5px;
}

p {
  margin: 0;
}

/*# sourceMappingURL=index.16556b3a.css.map */
