@font-face {
  font-family: 'MainBasketRun';
  src: url('./assets/static/fonts/LuckiestGuy-Regular.ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'FagoCol';
  src: url('./assets/static/fonts/FagoCoLf-Bold.ttf');
  /* font-weight: bold; */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FagoCoLfBlack';
  src: url('./assets/static/fonts/FagoCoLf-Black.ttf');
  font-style: normal;
  font-display: swap;
}

body {
    padding: 0;
    margin: 0;
    background-color: #df7734;
    background: linear-gradient(#dc6c25, #ff902a);
  }

canvas {
    /* width: 100%; */
      background-color: #df7734;
      position: absolute;
      /*top: 0;*/
      /*left: 50%;*/
      /*transform: translate(-50%, 0);*/
      width: 100%;
	height: 100%;
}

#fontsCont {
    font-family: 'MainBasketRun';
    font-size: 2pt;
    font-weight: bold;
    position: absolute;
}

#reportMessages {
  padding: 5px;
  color: white;
  z-index:10;
  position: absolute;
  font-size: 12pt;
  left: 10px;
  top:100px;
  width: 120px;
  opacity: 0.63;
  display:none;
}


#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: url('./assets/dom/images/preloader-bg.jpeg') center/cover no-repeat;
  align-items: center;
  z-index: 10;
}

.preloader-container {
  text-align: center;
}

#preloader img.logo {
  max-width: 100%;
  min-width: 100%;
  max-height: 100%;
}

.image-text-container {
  display: inline-block;
  position: relative;
}

.image-text-container img {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
}

.image-text-container p {
  display: inline-block;
  color: white;
  font-size: 24px;
  margin-left: 10px;
  font-weight: 900;
  text-align: left;
  width: 50px;
  margin:0 0 0 -3px;
  /* margin-left: -11px; */
}

#tutorial {
  display:none;
  font-family: FagoCol;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80vh;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  max-width: 800px;
  margin: auto;
  padding: 0px;
  color: white;
  border-radius: 32px;
  border: 4px solid #cf3a2b;
  background-color: #cf3a2b;

}

#tutorial-header {
  display: flex;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background:none;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
}

#tutorial-header h2 {
  font-family: 'FagoCoLfBlack';
  margin: 0; /* Remove default margin for <h2> */
  text-align: center; /* Center the text */
  flex-grow: 1;
  width: calc(100% - 20px);
  margin-left: 50px;
  font-size: 36px;
  font-weight: 900;
  line-height: 60px;

}

#tutorial-header .close-btn{
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  padding: 0;
  margin: 0;
  height: 32px;
  cursor: pointer;
}

#tutorial-header .close-btn img{
  height: 32px;
  width: 32px;
  margin: 10px;
}


#tutorial-content {
  flex-grow: 1;
  margin-top: 60px;
  overflow-y: auto;
  background: #721d18;
  padding: 10px;
  border-radius: 0 0 32px 32px;
}

#tutorial-content .inner-content{
  margin: 12px;
}

#tutorial-content .block {
  margin-bottom: 32px;
}


#tutorial-content h4 {
  padding-top: 10px;
  font-family: 'FagoCoLfBlack';
  font-size: 24px;
  margin: 16px 0 20px 0;
}

#tutorial-content h6 {
  font-family: 'FagoCoLfBlack';
  font-size: 16px;
  font-weight: 900;
}

#tutorial-content p {
  font-size: 16px;
  font-weight: 900;
  line-height: 18px;
}

#tutorial-content .product-line {
  padding-top: 10px;
  padding-bottom: 10px;
}



.product-line {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
      justify-content: center;
      align-items: center;
}



.cart-control {
  display: flex;
  height: 200px;
  margin-bottom: 20px;
  background:  url('./assets/cart.png') center no-repeat;
  /* justify-content:space-evenly */
}

.cart-control img {
  display: inline-block;
  text-align: center;
  margin: auto;
  padding-top: 90px;
}

.cart-control  img:nth-of-type(2) {
  padding-top: 110px;
}

.item-content {
  flex-grow: 1;
}


.items-list .item  {
  padding: 0;
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
}

.items-list .item img  {
  padding: 10px 16px 10px 10px;
}

h6 {
  margin: 0 0 5px;
}

p {
  margin: 0;
}
